import React from 'react'

import { ArticleListItem } from './ArticleListItem'

type ArticleListProps = {
  articles: any[]
}

export const ArticleList: React.FC<ArticleListProps> = ({
  articles
}) => {
  return (
    <div>
      {articles && articles.map(({ node: article }) => (
        <ArticleListItem
          slug={article.slug}
          title={article.title}
          excerpt={article.excerpt}
        />
      ))}
    </div>
  )
}