import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { graphql } from 'gatsby'

import { Layout } from '../components/Layout'
import { Container } from '../components/Container'
import { SEO } from '../components/SEO'
import { ArticleList } from '../components/ArticleList'

const CategoryWrapper = styled.article`
  ${tw`pt-8`}

  h1 {
    ${tw`mb-6`}
    ${tw`text-5xl`}
    ${tw`font-semibold`}
  }
`

const Category = ({
  data
}) => {
  return (
    <Layout>
      <SEO title={data.category.name} />
      <CategoryWrapper>
        <Container>
          <h1>{data.category.name}</h1>
          <ArticleList articles={data.articles.edges} />
        </Container>
      </CategoryWrapper>
    </Layout>
  )
}

export default Category

export const articleQuery = graphql`
  query ArticlesByCategory($categorySlug: String!) {
    category: strapiCategory(slug: { eq: $categorySlug }) {
      name
    }
    articles: allStrapiArticle(
      filter: {
        categories: {elemMatch: {slug: {eq: $categorySlug}}}
      },
      sort: {
        fields: publishdate,
        order: DESC
      }
    ) {
      edges {
        node {
          slug
          title
          excerpt
        }
      }
    }
  }
`