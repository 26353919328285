import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Link, navigate } from 'gatsby'

import { RichText } from '../components/RichText'

const ArticleListItemWrapper = styled.article`
  ${tw`my-8`}
  ${tw`rounded-lg`}
  ${tw`px-10`}
  ${tw`py-6`}
  ${tw`shadow`}
  ${tw`cursor-pointer`}
  ${tw`transition-all`}
  ${tw`duration-100`}
  ${tw`ease-out`}

  h2 {
    ${tw`inline-block`}
    ${tw`border-b-4`}
    ${tw`border-white`}
    ${tw`text-3xl`}
    ${tw`font-semibold`}
  }

  .read-more {
    ${tw`font-serif`}
    ${tw`font-bold`}
  }

  &:hover {
    transform: scale(1.05);
    ${tw`bg-gray-100`}

    h2 {
      ${tw`border-b-4`}
      ${tw`border-dotted`}
      ${tw`border-gray-500`}
    }
  }
`

type ArticleListItemProps = {
  slug: string
  title: string
  excerpt: string
}

export const ArticleListItem: React.FC<ArticleListItemProps> = ({
  excerpt,
  slug,
  title
}) => {
  const handleArticleClick = React.useCallback(() => {
    navigate(`/articles/${slug}/`)
  }, [slug])

  return (
    <ArticleListItemWrapper onClick={handleArticleClick}>
      <h2>
        <Link to={`/articles/${slug}/`}>{title}</Link>
      </h2>
      <RichText
        content={excerpt}
      />
      <Link to={`/articles/${slug}/`} className='read-more'>Read more &#x2663;</Link>
    </ArticleListItemWrapper>
  )
}